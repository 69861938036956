<template>
  <section v-loading="loading">
    <div v-if="!entitiesData.length">
      <el-empty description="No data"></el-empty>
    </div>
    <div v-else-if="!isKanban">
      <el-table :data="entitiesData" style="width: 100%" class="sidemenu">
        <el-table-column
          v-for="field in getAllFields"
          :key="field.label"
          fixed
          style="word-break: break-word;"
          :resizable="true"
          :prop="field.key"
          :label="field.label"
          :width="isEdit ? '260' : '180'"
        >
            <template slot="header">
              <div>
                {{ field.label }}
                <span v-if="checkFieldRequired(field)"
                  style="color: red; font-weight: bold"
                  >*</span
                >
              </div>
            </template>
          <template slot-scope="scope">
            <div>
              <SingleLineTextExecute
                v-if="
                  field.input_type === 'SINGLE_LINE_TEXT' ||
                  field.input_type === 'EMAIL' ||
                  field.input_type === 'PASSWORD' ||
                  field.input_type === 'MASKED' && !checkhideFields(field)
                "
                :data="field"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></SingleLineTextExecute>
              <!-- {{scope.row[field.key]}} -->
              <SelectExecute
                v-if="field.input_type === 'SELECT'&& !checkhideFields(field)"
                :data="field"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></SelectExecute>
              <HorizontalLineExecute
                v-if="field.input_type === 'HORIZONTAL_LINE'"
                :data="field"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></HorizontalLineExecute>
              <DateExecute
                v-if="field.input_type === 'DATE' && !checkhideFields(field)"
                :data="field"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></DateExecute>
              <DateTimeExecute
                :data="field"
                v-if="field.input_type === 'DATE_TIME' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></DateTimeExecute>
              <DateTimeRangeExecute
                :data="field"
                v-if="field.input_type === 'DATE_TIME_RANGE' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></DateTimeRangeExecute>
              <DateRangeExecute
                :data="field"
                v-if="field.input_type === 'DATE_RANGE' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></DateRangeExecute>
              <CheckBoxGroupExecute
                :data="field"
                v-if="field.input_type === 'CHECKBOX_GROUP' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></CheckBoxGroupExecute>
              <TimeExecute
                :data="field"
                v-if="field.input_type === 'TIME' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></TimeExecute>
              <TimeRangeExecute
                :data="field"
                v-if="field.input_type === 'TIME_RANGE' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              >
              </TimeRangeExecute>
              <FileExecute
                :data="field"
                v-if="field.input_type === 'FILE' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></FileExecute>
              <MultiLineTextExecute
                :data="field"
                v-if="
                  field.input_type === 'MULTI_LINE_TEXT' ||
                  field.input_type === 'RICH_TEXT'  && !checkhideFields(field)
                "
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></MultiLineTextExecute>
              <MultiSelectExecute
                :data="field"
                v-if="field.input_type === 'MULTI_SELECT' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></MultiSelectExecute>
              <YesOrNoExecute
                :data="field"
                v-if="field.input_type === 'YES_OR_NO' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></YesOrNoExecute>
              <NumberExecute
                :data="field"
                v-if="field.input_type === 'NUMBER' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></NumberExecute>

              <PhoneCountryCodeExecute
                :data="field"
                v-if="field.input_type === 'PHONE_COUNTRY_CODE' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></PhoneCountryCodeExecute>
              <CurrencyExecute
                :data="field"
                v-if="field.input_type === 'CURRENCY' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></CurrencyExecute>
              <HeadingExecute
                :data="field"
                v-if="field.input_type === 'HEADING' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></HeadingExecute>
              <CheckBoxExecute
                :data="field"
                v-if="field.input_type === 'CHECKBOX' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :fieldsData="fieldsData"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></CheckBoxExecute>
              <RadioExecute
                :data="field"
                v-if="field.input_type === 'RADIO' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></RadioExecute>
              <RadioGroupExecute
                :data="field"
                v-if="field.input_type === 'RADIO_BUTTON_GROUP' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :profilePage="true"
                :hasLabel="false"
                :checkIsDisabled="checkIsDisabled(field)"
                @applyFormRules="rulesEventEmitter"
              ></RadioGroupExecute>
              <!-- <ESignatureExecute
                :data="field"
                v-if="field.input_type === 'SIGNATURE'"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></ESignatureExecute> -->
              <GlobalVariableExecute
                :data="field"
                v-if="field.input_type === 'GLOBAL_VARIABLE' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></GlobalVariableExecute>

              <EntityVariableExecute
                :data="field"
                v-if="field.input_type === 'ENTITY_VARIABLE' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :fieldsData="fieldsData"
                :index="scope.$index"
                @entityDataUpdated="setDataToEntityVariables"
                @clearEntityFields="unsetEntityVariables"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></EntityVariableExecute>

              <EntityExecute
                :data="field"
                v-if="field.input_type === 'ENTITY' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :fieldsData="fieldsData"
                :index="scope.$index"
                @entityDataUpdated="setDataToEntityVariables"
                @clearEntityFields="unsetEntityVariables"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></EntityExecute>

              <ParagraphExecute
                :data="field"
                v-if="field.input_type === 'PARAGRAPH'"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></ParagraphExecute>
              <SingleLineContentExecute
                :data="field"
                v-if="field.input_type === 'SINGLE_LINE_CONTENT' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></SingleLineContentExecute>
              <AuthorizedSignatureExecute
                :data="field"
                v-if="field.input_type === 'AUTHORIZED_SIGNATURE' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></AuthorizedSignatureExecute>
              <!-- IMage html formula -->
              <FormulaExecute
                :data="field"
                v-if="field.input_type === 'FORMULA' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></FormulaExecute>
              <ActionButtonExecute
                :data="field"
                v-if="field.input_type === 'ACTION_BUTTON' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :fieldsData="fieldsData"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></ActionButtonExecute>

              <ImageExecute
                :data="field"
                v-if="field.input_type === 'IMAGE' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
                :hasLabel="hasLabel"
              ></ImageExecute>
              <VideoExecute
                :data="field"
                v-if="field.input_type === 'VIDEO'"
                :form="scope.row"
                :is-view="!isEdit"
                :profilePage="true"
                :hasLabel="hasLabel"
              ></VideoExecute>
              <HtmlExecute
                :data="field"
                v-if="field.input_type === 'HTML_CONTENT'"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></HtmlExecute>
              <ListExecute
                :data="field"
                v-if="field.input_type === 'LIST' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :profilePage="true"
                :hasLabel="hasLabel"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></ListExecute>
              <DIVExecute
                :data="field"
                v-if="field.input_type === 'DIVISION'"
                :form="scope.row"
                :profilePage="true"
              ></DIVExecute>
              <WeekDaysExecute
                :data="field"
                v-if="field.input_type == 'WEEKDAYS' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :profilePage="true"
                :hasLabel="hasLabel"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></WeekDaysExecute>
              <StarRatingExecute
                :data="field"
                v-if="field.input_type == 'STAR_RATING' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
                :hasLabel="hasLabel"
              ></StarRatingExecute>
              <AggregateFunctionExecute
                :data="field"
                v-if="field.input_type === 'AGGREGATE_FUNCTION' && !checkhideFields(field)"
                :form="scope.row"
                :is-view="!isEdit"
                :hasLabel="hasLabel"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
              ></AggregateFunctionExecute>
              <ConcatenateExecute
                :data="field"
                v-if="field.input_type === 'CONCATENATE' && !checkhideFields(field)"
                :form="scope.row"
                :hasLabel="hasLabel"
                :is-view="!isEdit"
                :profilePage="true"
                :checkIsDisabled="checkIsDisabled(field)"
                 @applyFormRules="rulesEventEmitter"
                  >
                </ConcatenateExecute>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed label="Actions" :min-width="120" v-if="isEdit">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-delete"
              @click="deleteKanban(scope.row)"
              style="color: red; margin-left: 10px"
              type="text"
            >
            </el-button>
            <!-- <el-button class="p-0" round>
              <img
                src="@/assets/img/icons/delete.png"
                alt="icon"
                @click="deleteKanban(scope.row)"
                width="15"
              />
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      v-else
      style="
        display: grid !important;
        grid-template-columns: 50% 50% !important;
        grid-column-gap: 10px;
      "
    >
      <el-card
        class="kanban-card"
        shadow="hover"
        v-for="(entity, index) in entitiesData"
        :key="index"
      >
        <el-scrollbar wrap-style="max-height: 400px;" style="border-width: 0px">
          <div
            style="
              display: grid !important;
              grid-template-columns: 50% 50% !important;
            "
          >
            <div
              class="kanban-card-column"
              v-for="field in fieldsData"
              :key="field.key"
              :prop="field.key"
            >
              <b>{{ field.label }}</b>
              <SingleLineTextExecute
                v-if="
                  field.input_type === 'SINGLE_LINE_TEXT' ||
                  field.input_type === 'EMAIL' ||
                  field.input_type === 'PASSWORD' ||
                  field.input_type === 'MASKED'
                "
                :data="field"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></SingleLineTextExecute>
              <!-- {{entity[field.key]}} -->
              <SelectExecute
                v-if="field.input_type === 'SELECT'"
                :data="field"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></SelectExecute>
              <HorizontalLineExecute
                v-if="field.input_type === 'HORIZONTAL_LINE'"
                :data="field"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></HorizontalLineExecute>
              <DateExecute
                v-if="field.input_type === 'DATE'"
                :data="field"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></DateExecute>
              <DateTimeExecute
                :data="field"
                v-if="field.input_type === 'DATE_TIME'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></DateTimeExecute>
              <DateTimeRangeExecute
                :data="field"
                v-if="field.input_type === 'DATE_TIME_RANGE'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></DateTimeRangeExecute>
              <DateRangeExecute
                :data="field"
                v-if="field.input_type === 'DATE_RANGE'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></DateRangeExecute>
              <CheckBoxGroupExecute
                :data="field"
                v-if="field.input_type === 'CHECKBOX_GROUP'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></CheckBoxGroupExecute>
              <TimeExecute
                :data="field"
                v-if="field.input_type === 'TIME'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></TimeExecute>
              <TimeRangeExecute
                :data="field"
                v-if="field.input_type === 'TIME_RANGE'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              >
              </TimeRangeExecute>
              <FileExecute
                :data="field"
                v-if="field.input_type === 'FILE'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></FileExecute>
              <MultiLineTextExecute
                :data="field"
                v-if="
                  field.input_type === 'MULTI_LINE_TEXT' ||
                  field.input_type === 'RICH_TEXT'
                "
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></MultiLineTextExecute>
              <MultiSelectExecute
                :data="field"
                v-if="field.input_type === 'MULTI_SELECT'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></MultiSelectExecute>
              <YesOrNoExecute
                :data="field"
                v-if="field.input_type === 'YES_OR_NO'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></YesOrNoExecute>
              <NumberExecute
                :data="field"
                v-if="field.input_type === 'NUMBER'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></NumberExecute>

              <PhoneCountryCodeExecute
                :data="field"
                v-if="field.input_type === 'PHONE_COUNTRY_CODE'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></PhoneCountryCodeExecute>
              <CurrencyExecute
                :data="field"
                v-if="field.input_type === 'CURRENCY'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></CurrencyExecute>
              <HeadingExecute
                :data="field"
                v-if="field.input_type === 'HEADING'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></HeadingExecute>
              <CheckBoxExecute
                :data="field"
                v-if="field.input_type === 'CHECKBOX'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :fieldsData="fieldsData"
              ></CheckBoxExecute>
              <RadioExecute
                :data="field"
                v-if="field.input_type === 'RADIO'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></RadioExecute>
              <RadioGroupExecute
                :data="field"
                v-if="field.input_type === 'RADIO_BUTTON_GROUP'"
                :form="entity"
                :is-view="!isEdit"
                :profilePage="true"
                :hasLabel="false"
              ></RadioGroupExecute>
              <GlobalVariableExecute
                :data="field"
                v-if="field.input_type === 'GLOBAL_VARIABLE'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></GlobalVariableExecute>

              <EntityVariableExecute
                :data="field"
                v-if="field.input_type === 'ENTITY_VARIABLE'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :fieldsData="fieldsData"
                :index="index"
                @entityDataUpdated="setDataToEntityVariables"
                @clearEntityFields="unsetEntityVariables"
              ></EntityVariableExecute>

              <EntityExecute
                :data="field"
                v-if="field.input_type === 'ENTITY'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :fieldsData="fieldsData"
                :index="index"
                @entityDataUpdated="setDataToEntityVariables"
                @clearEntityFields="unsetEntityVariables"
              ></EntityExecute>

              <ParagraphExecute
                :data="field"
                v-if="field.input_type === 'PARAGRAPH'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></ParagraphExecute>
              <SingleLineContentExecute
                :data="field"
                v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></SingleLineContentExecute>
              <AuthorizedSignatureExecute
                :data="field"
                v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></AuthorizedSignatureExecute>
              <!-- IMage html formula -->
              <FormulaExecute
                :data="field"
                v-if="field.input_type === 'FORMULA'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></FormulaExecute>
              <ActionButtonExecute
                :data="field"
                v-if="field.input_type === 'ACTION_BUTTON'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
                :fieldsData="fieldsData"
              ></ActionButtonExecute>

              <ImageExecute
                :data="field"
                v-if="field.input_type === 'IMAGE'"
                :form="entity"
                :is-view="!isEdit"
                :profilePage="true"
                :hasLabel="hasLabel"
              ></ImageExecute>
              <VideoExecute
                :data="field"
                v-if="field.input_type === 'VIDEO'"
                :form="entity"
                :is-view="!isEdit"
                :profilePage="true"
                :hasLabel="hasLabel"
              ></VideoExecute>
              <HtmlExecute
                :data="field"
                v-if="field.input_type === 'HTML_CONTENT'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="false"
                :profilePage="true"
              ></HtmlExecute>
              <ListExecute
                :data="field"
                v-if="field.input_type === 'LIST'"
                :form="entity"
                :is-view="!isEdit"
                :profilePage="true"
                :hasLabel="hasLabel"
              ></ListExecute>
              <DIVExecute
                :data="field"
                v-if="field.input_type === 'DIVISION'"
                :form="entity"
                :profilePage="true"
              ></DIVExecute>
              <WeekDaysExecute
                :data="field"
                v-if="field.input_type == 'WEEKDAYS'"
                :form="entity"
                :is-view="!isEdit"
                :profilePage="true"
                :hasLabel="hasLabel"
              ></WeekDaysExecute>
              <StarRatingExecute
                :data="field"
                v-if="field.input_type == 'STAR_RATING'"
                :form="entity"
                :is-view="!isEdit"
                :profilePage="true"
                :hasLabel="hasLabel"
              ></StarRatingExecute>
              <AggregateFunctionExecute
                :data="field"
                v-if="field.input_type === 'AGGREGATE_FUNCTION'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="hasLabel"
                :profilePage="true"
              ></AggregateFunctionExecute>
              <ConcatenateExecute
                :data="field"
                v-if="field.input_type === 'CONCATENATE'"
                :form="entity"
                :is-view="!isEdit"
                :hasLabel="hasLabel"
                :profilePage="true"
                  >
                </ConcatenateExecute>
            </div>
          </div>
        </el-scrollbar>
        <el-divider></el-divider>
        <div class="kanban-footer" v-if="isEdit">
          <el-button
            class="kanban-footer-button"
            type="text"
            @click="deleteKanban(entity)"
            v-if="kanban_index != index"
            >Delete</el-button
          >
        </div>
      </el-card>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import { bus } from "../../main";
export default {
  props: [
    "fieldsData",
    "form",
    "isEdit",
    "hasLabel",
    "isKanban",
    "max_value",
    "min_value",
    "stepId",
    "templateRules","entityDataId",
     "entityId","templateId",
  ],
  mixins: [TemplateBuilderHelper],
  computed: {
    ...mapGetters("templatesData", [
      "getRepeatableTemplateIds",
      "getFileUploadURL",
    ]),
    getAllFields() {
      return (this.fieldsData || []).filter((e) => e.input_type != "SIGNATURE");
    },
  },
  beforeDestroy() {
    bus.$off("addApRepeatableData");
  },
  created() {
    bus.$on("addApRepeatableData", (id) => {
      if (id == this.stepId) {
        this.addRepeatableData();
      }
    });
    bus.$on("getApRepeatableData", (id) => {
      if (id == this.stepId) {
        this.sendRepeatableData();
      }
    });
  },
  async mounted() {
    this.rulesEventEmitter();
    this.loading = true;
    await Promise.all([this.getRepeatableIds(), this.setTemplateData()]);
    this.loading = false;
  },
  components: {
    SingleLineTextExecute: () =>
      import("../templates/formComponentsExecute/SingleLineTextExecute.vue"),
    SelectExecute: () =>
      import("../templates/formComponentsExecute/SelectExecute.vue"),
    HorizontalLineExecute: () =>
      import("../templates/formComponentsExecute/HorizontalLineExecute"),
    DateExecute: () => import("../templates/formComponentsExecute/DateExecute"),
    DateTimeExecute: () =>
      import("../templates/formComponentsExecute/DateTimeExecute"),
    DateTimeRangeExecute: () =>
      import("../templates/formComponentsExecute/DateTimeRangeExecute"),
    DateRangeExecute: () =>
      import("../templates/formComponentsExecute/DateRangeExecute"),
    TimeExecute: () => import("../templates/formComponentsExecute/TimeExecute"),
    TimeRangeExecute: () =>
      import("../templates/formComponentsExecute/TimeRangeExecute"),
    FileExecute: () => import("../templates/formComponentsExecute/FileExecute"),
    MultiLineTextExecute: () =>
      import("../templates/formComponentsExecute/MultiLineTextExecute"),
    MultiSelectExecute: () =>
      import("../templates/formComponentsExecute/MultiSelectExecute"),
    NumberExecute: () =>
      import("../templates/formComponentsExecute/NumberExecute"),
    YesOrNoExecute: () =>
      import("../templates/formComponentsExecute/YesOrNoExecute"),
    HeadingExecute: () =>
      import("../templates/formComponentsExecute/HeadingExecute"),
    CheckBoxExecute: () =>
      import("../templates/formComponentsExecute/CheckBoxExecute"),
    RadioExecute: () =>
      import("../templates/formComponentsExecute/RadioExecute"),
    RadioGroupExecute: () =>
      import("../templates/formComponentsExecute/RadioButtonGroupExecute"),
    // ESignatureExecute: () =>
    //   import("../templates/formComponentsExecute/ESignatureExecute"),
    ParagraphExecute: () =>
      import("../templates/formComponentsExecute/ParagraphExecute"),
    GlobalVariableExecute: () =>
      import("../templates/formComponentsExecute/GlobalVariableExecute"),
    EntityVariableExecute: () =>
      import("../templates/formComponentsExecute/EntityVariableExecute"),
    EntityExecute: () =>
      import("../templates/formComponentsExecute/EntityExecute"),
    SingleLineContentExecute: () =>
      import("../templates/formComponentsExecute/SingleLineContentExecute"),
    AuthorizedSignatureExecute: () =>
      import("../templates/formComponentsExecute/AuthorizedSignatureExecute"),
    ImageExecute: () =>
      import("../templates/formComponentsExecute/ImageExecute"),
    VideoExecute: () =>
      import("../templates/formComponentsExecute/VideoExecute"),
    FormulaExecute: () =>
      import("../templates/formComponentsExecute/FormulaExecute"),
    ActionButtonExecute: () =>
      import("../templates/formComponentsExecute/ActionButtonExecute"),
    HtmlExecute: () => import("../templates/formComponentsExecute/HtmlExecute"),

    ListExecute: () => import("../templates/formComponentsExecute/ListExecute"),
    DIVExecute: () =>
      import("../templates/formComponentsExecute/DIVExecute.vue"),
    PhoneCountryCodeExecute: () =>
      import("../templates/formComponentsExecute/PhoneCountryCodeExecute.vue"),
    CurrencyExecute: () =>
      import("../templates/formComponentsExecute/CurrencyExecute.vue"),
    WeekDaysExecute: () =>
      import("../templates/formComponentsExecute/WeekDaysExecute.vue"),
    StarRatingExecute: () =>
      import("../templates/formComponentsExecute/StarRatingExecute.vue"),

    CheckBoxGroupExecute: () =>
      import("../templates/formComponentsExecute/CheckBoxGroupExecute.vue"),
    AggregateFunctionExecute: () =>
      import("../templates/formComponentsExecute/AggregateFunctionExecute.vue"),
    ConcatenateExecute: () =>
      import("../templates/formComponentsExecute/ConcatenateExecute.vue"),
  },
  data() {
    return {
      newEntityData: {},
      kanban_index: -1,
      kanbanDialog: false,
      isOn: false,
      loading: false,
      tableHeaders: [],
      repeatableTemplateTitle: "",
      entitiesData: [],
      lastFieldValueY: null,
      currentPageBaseWidth: 900,
      selectedRow: {},
      allFormData: [],
      isNewDataAdded: false,
      selectedRowIndex: null,
      isEditingData: false,
      repeatableIds: [],
      isEditingKanban: false,
      mappedTemplateData: {},
      hideFields: [],
      disabledFields: [],
      showTemplateFields: {},
      scrollBottom: 0,
    };
  },
  methods: {
    checkFieldRequired(data) {
      if (data) {
        if (data.allow_multiple && data.inputType == "WEEKDAYS") {
          if (data.min_value > 0) {
            return true;
          }
          return false;
        } else {
          return data?.validations?.required;
        }
      }
      return false;
    },
    checkIsDisabled(field) {
      return this.disabledFields.find((e) => e == field.key) ? true : false;
    },
    checkhideFields(field) {
      return this.hideFields.find((e) => e == field.key) ? true : false;
    },
    rulesEventEmitter() {
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
    },
    async setDataToEntityVariables(data, parent, label, changed, dataIndex, index) {
      if (index > -1 && this.entitiesData[index]) {
        let selectedRow = this.entitiesData[index];
        if (label) {
          this.$set(selectedRow, parent.key + "/name", label);
        }
        this.fieldsData.forEach((field) => {
          if (field.relationship_key == parent.key && data.entityData) {
            if (field.global_variable_entity_field) {
              let templateId = "";
              let key = "";
              let value = "";
              if (field.global_variable_entity_field.includes("#")) {
                templateId = field.global_variable_entity_field.split("#")[0];
                key = field.global_variable_entity_field.split("#")[1];
              } else {
                templateId = field.global_variable_entity_field;
              }
              if (parent?.allow_multiple) {
                if (data.length) {
                  let dataValues = [],
                    result = 0,
                    min;
                  let existedIds = (
                    this.form &&
                    this.form[field.key] &&
                    this.form[field.key].length
                      ? this.form[field.key]
                      : []
                  )
                    .filter((e) => e.parentDataId)
                    .flatMap((dt) => dt.parentDataId);
                  (data || []).forEach((e) => {
                    if (
                      e?.entityData?.[templateId]?.[key] &&
                      existedIds.indexOf(e._id) == -1
                    ) {
                      if (field.inputType == "DATA_TABLE") {
                        if (e.entityData[templateId][key].length) {
                          e?.entityData[templateId][key].forEach((dt) => {
                            dataValues.push({
                              ...dt,
                              ...{
                                parentDataId: e._id,
                                parentDataName: e.parentDataName,
                              },
                            });
                          });
                        }
                      } else if (this.isNumber(e.entityData[templateId][key])) {
                        let v = e.entityData[templateId][key];
                        if (
                          field.variable_action == "SUM" ||
                          field.variable_action == "AVERAGE"
                        ) {
                          result = result + parseInt(v);
                        } else if (
                          field.variable_action == "HIGHEST" &&
                          v > result
                        ) {
                          result = parseInt(v);
                        } else if (field.variable_action == "LOWEST") {
                          if (v < min || min == undefined) {
                            min = parseInt(v);
                          }
                        }
                      }
                    }
                  });
                  if (existedIds.length) {
                    dataValues = [
                      ...dataValues,
                      ...(this.form[field.key] || []),
                    ];
                  }
                  if (field.inputType == "DATA_TABLE") {
                    this.$set(this.form, field.key, dataValues);
                  } else {
                    if (field.variable_action == "AVERAGE") {
                      let avg = result / data.length;
                      this.$set(this.form, field.key, avg);
                    } else if (field.variable_action == "LOWEST") {
                      this.$set(this.form, field.key, min);
                    } else {
                      this.$set(this.form, field.key, result);
                    }
                  }
                }
              } else {
                if (
                  data.entityData &&
                  data.entityData[templateId] &&
                  data.entityData[templateId][key]
                ) {
                  value = data.entityData[templateId][key];
                }
                if (selectedRow[field.key]) {
                  selectedRow[field.key] = value;
                } else {
                  this.$set(selectedRow, field.key, value);
                }
                this.entitiesData[this.index] = { ...selectedRow };
              }
            }
          }
        });
      }
    },
    isNumber(value) {
      return typeof value === "number" || !isNaN(parseFloat(value));
    },
    unsetEntityVariables(parent, index) {
      if (index > -1 && this.entitiesData[index]) {
        let selectedRow = this.entitiesData[index];
        this.fieldsData.forEach((field) => {
          if (field.relationship_key == parent.key) {
            this.$set(selectedRow, field.key, "");
          }
        });
        this.entitiesData[this.index] = { ...selectedRow };
      }
    },
    sendRepeatableData() {
      bus.$emit("sendApRepeatableData", {
        data: this.entitiesData,
        id: this.stepId,
      });
    },
    async addRepeatableData() {
      if (this.max_value > this.entitiesData.length) {
        let key = (Math.random() + 1).toString(36).substring(7);
        if (
          this.getRepeatableTemplateIds &&
          this.getRepeatableTemplateIds.length
        ) {
          let objKey = this.getRepeatableTemplateIds.find((key) => {
            let freeIndexKey = this.entitiesData.find(
              (e) => e && e.indexKey == key
            );
            if (!freeIndexKey) {
              return key;
            }
          });
          if (objKey) {
            key = objKey;
          }
        }
        let selectedRow = { indexKey: key };
        this.entitiesData.push({ ...selectedRow });
        console.log("isOn", this.entitiesData);
      } else {
        this.$notify({
          title: "Warning",
          message: "Max number of records already added. Can't add new data.",
          type: "warning",
        });
      }
    },
    deleteKanban(data) {
      this.$confirm("Are you sure to delete? ", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async () => {
        let index = this.entitiesData.findIndex(
          (e) => e.indexKey == data.indexKey
        );
        this.entitiesData.splice(index, 1);
        this.$notify({
          title: "Success",
          message: "Data deleted successfully",
          type: "success",
        });
      });
    },
    async getRepeatableIds() {
      await this.$store.dispatch(
        "templatesData/fetchRepeatableTemplateIds",
        this.max_value
      );
    },
    async setTemplateData() {
      this.entitiesData = [];
      if (this.form && Object.keys(this.form).length > 0) {
        this.entitiesData = this.form[Object.keys(this.form)[0]];
      }
    },
    handleScroll(event) {
      // this.scrollTop = event.target.scrollTop;

      //console.log("scrollTop:", this.scrollTop);
      const scrollTop = event.target.scrollTop;
      const scrollHeight = event.target.scrollHeight;
      const clientHeight = event.target.clientHeight;
      this.scrollBottom = scrollHeight - scrollTop - clientHeight;
    },
  },
};
</script>
<style scoped lang="scss">
.sidemenu {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  //  direction: rtl;
}
.sidemenu::-webkit-scrollbar {
  width: 1em;
  height: 0.5em;
}
.sidemenu::-webkit-scrollbar-horizontal {
  /* Select the down or left scroll button when it's being hovered by the mouse */
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #9b9b9b;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.sidemenu::-webkit-scrollbar-button:single-button {
  width: 16px;
  height: 13px;
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement {
  // border-width: 8px 8px 8px 8px;
  // border-color: transparent transparent #555555 transparent;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
.sidemenu::-webkit-scrollbar-button:single-button:horizontal:increment {
  //  border-width: 8px 8px 8px 8px;
  // border-color: #555555 transparent transparent transparent;
}

.sidemenu::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}
.el-table .cell {
  word-break: break-word;
}
.el-table .td {
  word-wrap: break-word;
}
.kanban-card-column {
  // display: inline-block;
  word-wrap: break-word;
  // margin: 5px;
  padding: 5px;
  margin-bottom: 5px;
}
.kanban-card {
  width: 100%;
  margin: 5px;
  display: inline-block;
  border: 1px solid #cfd4d1;
}
.kanban-card-content {
  font-family: "system-ui";
  font-size: 16px;
}
.kanban-footer-button {
  color: #f73620;
  float: right;
}
</style>
